<template>
  <BaseSimpleLayout class="accept-detail">
    <template #title>受注情報詳細</template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        v-if="customerId"
        size="large"
        type="info"
        @click="backCustomerDetail"
      >
        顧客管理情報
      </el-button>
      <el-button size="large" type="info" @click="router.push('/accept')">
        受注一覧
      </el-button>
      <el-button
        v-if="status === 2 && orderRole"
        size="large"
        type="primary"
        @click="router.push('/accept/create/basic')"
      >
        編集
      </el-button>
      <el-button
        v-if="status === 2 && isShip && orderRole"
        size="large"
        type="success"
        @click="displayDialog = true"
      >
        出荷依頼
      </el-button>
      <el-button
        v-if="status === 3 && isShip && adminRole"
        size="large"
        type="success"
        @click="displayConfirmDialog = true"
      >
        出荷依頼確定
      </el-button>
      <el-button
        v-if="status < 3 && orderRole"
        size="large"
        type="danger"
        @click="displayDeleteDialog = true"
      >
        削除
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="accept-detail-table-area">
        <div class="accept-detail-table-line">
          <div class="table-title">基本情報</div>
          <BaseDataTable
            :table-data="basicData"
            table-size="small"
            :label-width="110"
          />
        </div>
        <div class="accept-detail-table-line">
          <div class="table-title">発送製品情報</div>
          <el-table
            :data="productData"
            stripe
            border
            style="margin: 0px auto 10px"
            class="accept-detail-product"
            empty-text="製品が存在しません"
            size="small"
          >
            <el-table-column prop="productName" label="製品名" align="center">
              <template #default="scope">
                {{ productNameChanger[scope.row.productId].name }}
              </template>
            </el-table-column>
            <el-table-column
              prop="productPrice"
              label="価格"
              width="70px"
              align="center"
            />
            <el-table-column
              prop="productQty"
              label="数"
              width="40px"
              align="center"
            />
          </el-table>
          <div v-if="displayCarInfo" class="table-title">取付車両情報</div>
          <BaseDataTable
            v-if="displayCarInfo"
            :table-data="carData"
            table-size="small"
            :label-width="125"
          />
        </div>
        <div class="accept-detail-table-line">
          <div class="table-title">取付先/発送先情報</div>
          <BaseDataTable
            :table-data="shipmentData"
            table-size="small"
            :label-width="120"
          />
        </div>
        <div class="accept-detail-table-line">
          <div class="table-title">その他/備考</div>
          <BaseDataTable
            :table-data="noteData"
            table-size="small"
            :label-width="95"
          />
          <div class="table-title">入力情報</div>
          <BaseDataTable
            :table-data="otherData"
            table-size="small"
            :label-width="85"
          />
          <div class="table-title">SF情報</div>
          <el-table
            :data="sfData"
            style="margin: 0px auto 10px"
            class="accept-detail-sf"
            empty-text="SF情報が存在しません"
            size="small"
          >
            <el-table-column prop="label" label="項目" width="120px" />
            <el-table-column prop="value" label="リンク">
              <template #default="scope">
                <a :href="scope.row.link" target="_blank">{{
                  scope.row.value
                }}</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-dialog v-model="displayDialog" title="出荷依頼" width="400px">
        <span>
          <b>この受注情報で出荷依頼しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="shipRequest">
            実行
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="displayConfirmDialog"
        title="出荷依頼確定"
        width="400px"
      >
        <span>
          <b>この受注情報で出荷依頼を確定しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayConfirmDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="shipRequestCancel">
            差し戻し
          </el-button>
          <el-button size="large" type="primary" @click="shipRequestConfirm">
            実行
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="displayDeleteDialog"
        title="受注情報削除"
        width="400px"
      >
        <span>
          <b>この受注情報を削除しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDeleteDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteAccept">
            実行
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import {
    createBasicTableData,
    createCarTableData,
    createShipmentTableData,
    depotName
  } from '@/libs/accept';
  import {
    isShortageCarModel,
    isShortageExpectedCarModel
  } from '@/libs/shortage';
  import { AcceptDetailsElement } from '@/types/accept';
  import { DetailTableData } from '@/types/common';
  import { ProductReplaceData } from '@/types/master/product';
  import { acceptStatus } from '@/libs/accept';
  import { formatDate, formatDateTime } from '@/libs/dateFormat';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';

  const props = defineProps({
    acceptId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const basicData = ref<DetailTableData[]>([]);
  const carData = ref<DetailTableData[]>([]);
  const productData = ref<AcceptDetailsElement[]>([]);
  const shipmentData = ref<DetailTableData[]>([]);
  const noteData = ref<DetailTableData[]>([]);
  const otherData = ref<DetailTableData[]>([]);
  const productNameChanger = ref<ProductReplaceData>({});
  const status = ref(0);
  const isShip = ref(false);
  const displayCarInfo = ref(false);
  const loadingFlag = ref(false);
  const displayDialog = ref(false);
  const displayConfirmDialog = ref(false);
  const displayDeleteDialog = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));
  const adminRole = ref<boolean>(rolePermission(['SHIPADMIN', 'ORDERADMIN']));
  const customerId = ref<string | null>(null);
  const sfData = computed(() => {
    const data: DetailTableData[] = [];
    try {
      const parsedData = JSON.parse(store.state.accept.detailData.sfOrderCode);
      if (parsedData.opportunityId) {
        data.push({
          label: '商談',
          value: parsedData.opportunityId,
          link: `https://gmsjp.lightning.force.com/lightning/r/Opportunity/${parsedData.opportunityId}/view`
        });
      }
      if (parsedData.salesforceId) {
        data.push({
          label: 'MCCS取付',
          value: parsedData.salesforceId,
          link: `https://gmsjp.lightning.force.com/lightning/r/Opportunity/${parsedData.salesforceId}/view`
        });
      }
    } catch (error) {
      // sfOrderCodeがJSON形式ではない場合何もしない
    }
    return data;
  });
  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('productsMaster/fetch'),
      store.dispatch('supportShopsMaster/fetch'),
      store.dispatch('accept/fetchDetail', props.acceptId)
    ]);
    const detailData = store.state.accept.detailData;
    const supportShopChanger = store.state.supportShopsMaster.replaceData;
    productNameChanger.value = store.state.productsMaster.replaceData;
    if (!detailData) return router.push('/accept');
    status.value = detailData.status;
    isShip.value = detailData.isShip;
    basicData.value = createBasicTableData(detailData);
    basicData.value[6].value = detailData.financeName;
    basicData.value[10].value = detailData.shopName;
    basicData.value[9].value = detailData.companyName;
    basicData.value[8].value = detailData.companyGroupName;
    basicData.value[15].value = detailData.orderCompanyName
      ? detailData.orderCompanyName
      : '-';
    basicData.value.unshift({
      label: 'ステータス',
      value: acceptStatus(detailData.status, detailData.isRemand)
    });
    if (detailData.type === 1 && detailData.isShip) {
      carData.value = createCarTableData(detailData);
      carData.value[2].value = detailData.carMakerName;
      displayCarInfo.value = true;
    }
    carData.value.push({
      label: 'ハーネス品番',
      value: detailData.harnessPartCode ? detailData.harnessPartCode : '-'
    });
    carData.value.push({
      label: 'ハーネス名称',
      value: detailData.harnessName ? detailData.harnessName : '-'
    });
    const isShortage = isShortageCarModel(detailData.carModel);
    const isShortageExpected = isShortageExpectedCarModel(
      detailData.carModel,
      detailData.ignitionType
    );
    const statusMessage = isShortageExpected
      ? '出荷依頼の前に在庫数を確認してください！'
      : isShortage
      ? '在庫欠品中です！本当に受注してよろしいですか？'
      : '-';
    carData.value.push({
      label: '欠品状況',
      value: statusMessage
    });
    productData.value = detailData.details;
    shipmentData.value = createShipmentTableData(detailData);
    customerId.value = store.state.management.customerId;
    if (detailData.supportShopId) {
      shipmentData.value[1].value =
        supportShopChanger[detailData.supportShopId];
    } else {
      shipmentData.value[1].value = basicData.value[10].value;
    }
    noteData.value = [
      {
        label: '拠点',
        value: depotName[detailData.depotId]
      },
      {
        label: '着指定日',
        value: detailData.arrivalDate
          ? formatDate(Number(detailData.arrivalDate))
          : '-'
      },
      {
        label: '東京専用\r\n備考',
        value: detailData.note
      },
      {
        label: '発送時連携\r\n用備考',
        value: detailData.shipNote
      }
    ];
    otherData.value = [
      {
        label: '作成日時',
        value: formatDateTime(Number(detailData.createdAt))
      },
      {
        label: '作成者',
        value: detailData.registerUserName
      },
      {
        label: '更新日時',
        value: formatDateTime(Number(detailData.updatedAt))
      },
      {
        label: '更新者',
        value: detailData.updateUserName
      }
    ];
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const shipRequest = async () => {
    await store.dispatch('accept/request', {
      acceptId: props.acceptId,
      processType: 1
    });
  };

  const shipRequestConfirm = async () => {
    await store.dispatch('accept/request', {
      acceptId: props.acceptId,
      processType: 2
    });
  };

  const shipRequestCancel = async () => {
    await store.dispatch('accept/request', {
      acceptId: props.acceptId,
      processType: 9
    });
  };

  const deleteAccept = async () => {
    await store.dispatch('accept/delete', props.acceptId);
  };

  const backCustomerDetail = () => {
    router.push('/management/' + customerId.value);
  };
  initialize();
</script>
<style lang="scss">
  .accept-detail {
    .base-data-table {
      width: 330px;
      margin: 0px auto;
    }
    .main-container {
      width: 98%;
      margin-top: 5px;
    }
    .table-title {
      font-size: 18px;
      font-weight: 600;
    }
    .accept-detail-table-area {
      width: 1410px;
      .accept-detail-table-line {
        width: 330px;
        margin-left: 10px;
        float: left;
      }
    }
    .accept-detail-button-area {
      clear: left;
      margin: 20px 0px;
    }
    .accept-detail-product {
      .cell {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .el-button {
      font-weight: 600;
    }
    .el-table {
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
  }
</style>
