<template>
  <div v-if="loadingFlag" class="ship-note">
    <div class="button-area">
      <el-button
        size="large"
        type="info"
        @click="router.push('/ship/' + shipId)"
      >
        戻る
      </el-button>
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="deliveryNoteDialog = true"
      >
        備考編集
      </el-button>
      <el-button size="large" type="primary" @click="handlePrint">
        印刷
      </el-button>
    </div>
    <section class="sheet page">
      <div class="header-area">
        <ul class="text-right">
          <tr v-for="data in basicData" :key="data.label">
            <td class="text-left label">{{ data.label }}</td>
            <td class="text-right">{{ data.value }}</td>
          </tr>
        </ul>
      </div>
      <div class="note-title text-center">納 品 書</div>
      <div class="address-area">
        <div class="company-info">
          <ul>
            <li>{{ businessType === 1 ? '' : companyData?.id }}</li>
            <li class="company-name">{{ companyData?.name }} 御中</li>
            <li>〒 {{ companyData?.zip }}</li>
            <li>{{ createCompanyZip() }}</li>
            <li v-if="companyData?.addressSub" class="address-sub">
              {{ companyData.addressSub }}
            </li>
            <li>Tel: {{ companyData?.tel }}</li>
            <li v-if="companyData?.fax">Fax: {{ companyData.fax }}</li>
          </ul>
        </div>
        <div class="in-house">
          <ul>
            <li class="logo"><img src="@/assets/gms_logo_y_jp.jpg" /></li>
            <li>Global Mobility Service 株式会社</li>
            <li>〒 101-0035</li>
            <li>東京都千代田区神田紺屋町15</li>
            <li>グランファースト4階</li>
            <li>Tel: 03-6384-0202</li>
            <li>Fax: 03-6800-3227</li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="product-detail-area">
        <table class="product-detail-table">
          <thead>
            <tr v-if="businessType === 1">
              <th class="item">品名</th>
              <th class="amount">数量</th>
              <th class="unit">単位</th>
            </tr>
            <tr v-if="businessType === 2">
              <th class="item-number">品番</th>
              <th class="item-cs">品名</th>
              <th class="jan-code">JAN</th>
              <th class="amount-cs">数量</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="businessType === 1">
              <tr
                v-for="product in productData"
                :key="product.name"
                class="dataline"
              >
                <td class="text-left">{{ product.name }}</td>
                <td class="text-center">{{ product.qty }}</td>
                <td class="text-center">
                  {{ unitName(product.productType) }}
                </td>
              </tr>
            </template>

            <template v-if="businessType === 2">
              <tr
                v-for="product in productData"
                :key="product.name"
                class="dataline"
              >
                <td class="text-center">{{ product.productCode }}</td>
                <td class="text-left">{{ product.name }}</td>
                <td class="text-center">{{ product.janCode }}</td>
                <td class="text-center">{{ product.qty }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="ship-detail-area">
        <table class="ship-detail-table">
          <thead>
            <tr>
              <th class="item">項目</th>
              <th class="target">詳細</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in tableData" :key="data.label" class="dataline">
              <td class="text-left">{{ data.label }}</td>
              <td class="text-left">{{ data.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <el-dialog v-model="deliveryNoteDialog" title="備考編集" width="600px">
      <el-form :model="deliveryNoteForm" label-width="0px">
        <el-form-item>
          <el-input
            v-model="deliveryNoteForm.note"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="large" type="info" @click="deliveryNoteDialog = false">
          キャンセル
        </el-button>
        <el-button size="large" type="primary" @click="updatedeliveryNote">
          更新
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import { ShipDetailData, ShipAcceptDetailState } from '@/types/ship';
  import { ShopDetailData } from '@/types/master/shop';
  import { CompanyDetailData } from '@/types/master/company';
  import { formatJpDate } from '@/libs/dateFormat';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';
  import { ignitionType } from '@/libs/harness';
  const props = defineProps({
    shipId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const tableData = ref<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const basicData = ref<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const loadingFlag = ref(false);
  let detailData: ShipDetailData | null = null;
  let shopData: ShopDetailData | null = null;
  const productData = ref<ShipAcceptDetailState[]>([]);
  const companyData = ref<CompanyDetailData | null>(null);
  const deliveryNoteForm = ref({
    note: ''
  });
  const deliveryNoteDialog = ref(false);
  const orderRole = rolePermission(['ORDERING', 'ORDERADMIN']);
  const businessType = ref(1);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('ship/fetchDetail', props.shipId);
    detailData = store.state.ship.detailData;
    if (!detailData) return router.push('/ship');
    await store.dispatch('shopMaster/fetch', detailData.shopId);
    shopData = store.state.shopMaster.detailData;
    if (!shopData) return router.push('/ship');
    await store.dispatch('companyMaster/fetch', shopData.companyId);
    companyData.value = store.state.companyMaster.detailData;
    if (detailData.acceptDetails) {
      productData.value = detailData.acceptDetails.sort(
        (a: ShipAcceptDetailState, b: ShipAcceptDetailState) => {
          if (a.productType >= b.productType) {
            return 1;
          }
          return -1;
        }
      );
    }
    if (!companyData.value) return router.push('/ship');
    businessType.value = detailData.businessType;
    basicData.value = [
      {
        label: '納品書番号',
        value: ('00000000' + detailData.acceptId).slice(-8)
      },
      {
        label: '発行日',
        value: formatJpDate(Date.now())
      }
    ];

    // type TableItemValue = string | string[]; // もしくは具体的な型に変更可能
    // type TableItem = {
    //   label: string;
    //   value: TableItemValue;
    // };

    // type TableItems = Record<string, TableItem>;
    // const tableItems: TableItems = {
    //   customerCode: {
    //     label: '顧客管理番号',
    //     value: detailData.customerCode
    //   },
    //   financeCode: {
    //     label: 'ファイナンス管理番号',
    //     value: detailData.financeCode
    //   },
    //   deviceSerial: {
    //     label: 'MCCSシリアル',
    //     value: createDeviceSerialList()
    //   },
    //   carMakerName: {
    //     label: 'メーカー',
    //     value: addTableData(detailData.carMakerName)
    //   },
    //   carType: {
    //     label: '車名',
    //     value: addTableData(detailData.carType)
    //   },
    //   carModel: {
    //     label: '型式',
    //     value: addTableData(detailData.carModel)
    //   },
    //   vin: {
    //     label: '取付対象車体番号',
    //     value: addTableData(detailData.vin)
    //   },
    //   ignitionType: {
    //     label: 'E/Gスタート方式',
    //     value: detailData.ignitionType
    //       ? ignitionType[detailData.ignitionType]
    //       : '-'
    //   },
    //   shopName: {
    //     label: '販売店名',
    //     value: shopData.name
    //   },
    //   shopZip: {
    //     label: '郵便番号',
    //     value: shopData.zip
    //   },
    //   shipZip: {
    //     label: '住所',
    //     value: createShipZip()
    //   },
    //   shopTel: {
    //     label: '電話番号',
    //     value: shopData.tel
    //   },
    //   shipStaffName: {
    //     label: '担当者',
    //     value: detailData.shipStaffName
    //   },
    //   deliveryNote: {
    //     label: '備考',
    //     value: detailData.deliveryNote
    //   },
    //   orderNumber: {
    //     label: '発注番号',
    //     value: detailData.orderNumber
    //   },
    //   deviceSerialCode: {
    //     label: '端末シリアル番号',
    //     value: createDeviceSerialList()
    //   },
    //   shopCode: {
    //     label: '販売店コード',
    //     value: shopData.shopCode
    //   },
    //   shipName: {
    //     label: '納入先名',
    //     value: detailData.shipName
    //   }
    // };

    // if (businessType.value === 1) {
    //   tableData.value = [
    //     'customerCode',
    //     'financeCode',
    //     'deviceSerial',
    //     'carMakerName',
    //     'carType',
    //     'carModel',
    //     'vin',
    //     'ignitionType',
    //     'shopName',
    //     'shopZip',
    //     'shipZip',
    //     'shopTel',
    //     'shipStaffName',
    //     'deliveryNote'
    //   ].map((key) => {
    //     return tableItems[key];
    //   });
    // } else if (businessType.value === 2) {
    //   tableData.value = [
    //     'orderNumber',
    //     'deviceSerialCode',
    //     'carMakerName',
    //     'carType',
    //     'carModel',
    //     'vin',
    //     'ignitionType',
    //     'shopCode',
    //     'shopName',
    //     'shipName',
    //     'shopZip',
    //     'shipZip',
    //     'shopTel',
    //     'shipStaffName',
    //     'deliveryNote'
    //   ].map((key) => {
    //     return tableItems[key];
    //   });
    // }

    type TableItemValue = string | string[];
    type TableItem = {
      label: string;
      value: TableItemValue; // valueがstringまたはstring[]を許可
    };

    type TableItems = Record<string, TableItem>;

    const tableItems: TableItems = {
      customerCode: {
        label: '顧客管理番号',
        value: detailData.customerCode
      },
      financeCode: {
        label: 'ファイナンス管理番号',
        value: detailData.financeCode
      },
      deviceSerial: {
        label: 'MCCSシリアル',
        value: createDeviceSerialList()
      },
      carMakerName: {
        label: 'メーカー',
        value: addTableData(detailData.carMakerName)
      },
      carType: {
        label: '車名',
        value: addTableData(detailData.carType)
      },
      carModel: {
        label: '型式',
        value: addTableData(detailData.carModel)
      },
      vin: {
        label: '取付対象車体番号',
        value: addTableData(detailData.vin)
      },
      ignitionType: {
        label: 'E/Gスタート方式',
        value: detailData.ignitionType
          ? ignitionType[detailData.ignitionType]
          : '-'
      },
      shopName: {
        label: '販売店名',
        value: shopData.name
      },
      shopZip: {
        label: '郵便番号',
        value: shopData.zip
      },
      shopAddress: {
        label: '住所',
        value: createShopAddress()
      },
      shopTel: {
        label: '電話番号',
        value: shopData.tel
      },
      shipStaffName: {
        label: '納入先担当者',
        value: detailData.shipStaffName
      },
      deliveryNote: {
        label: '備考',
        value: detailData.deliveryNote
      },
      orderNumber: {
        label: '発注番号',
        value: detailData.orderNumber || ''
      },
      deviceSerialCode: {
        label: '端末シリアル番号',
        value: createDeviceSerialList()
      },
      shopCode: {
        label: '販売店コード',
        value: shopData.shopCode
      },
      shipName: {
        label: '納入先名',
        value: detailData.shipName
      }
    };

    // keysの型をstring[]にして、string[]に変換
    const getTableData = (keys: string[]): { label: string; value: string }[] =>
      keys.map((key) => {
        const item = tableItems[key];
        return {
          label: item.label,
          // valueをstring型に変換
          value: Array.isArray(item.value) ? item.value.join(', ') : item.value
        };
      });

    if (businessType.value === 1) {
      tableData.value = getTableData([
        'customerCode',
        'financeCode',
        'deviceSerial',
        'carMakerName',
        'carType',
        'carModel',
        'vin',
        'ignitionType',
        'shopName',
        'shopZip',
        'shopAddress',
        'shopTel',
        'shipStaffName',
        'deliveryNote'
      ]);
    } else if (businessType.value === 2) {
      tableData.value = getTableData([
        'orderNumber',
        'deviceSerialCode',
        'carMakerName',
        'carType',
        'carModel',
        'ignitionType',
        'shopCode',
        'shopName',
        'shopZip',
        'shopAddress',
        'shopTel',
        'deliveryNote',
        'shipName',
        'shipStaffName'
      ]);
    }

    deliveryNoteForm.value.note = detailData.deliveryNote;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const unitName = (type: number): string => {
    if (type === 1) return 'セット';
    if (type === 2) return '個';
    if (type === 3) return '台';
    if (type === 4) return '台';
    return '';
  };
  const createCompanyZip = () => {
    if (!companyData.value) return '';
    return (
      companyData.value.prefecture +
      companyData.value.city +
      companyData.value.address
    );
  };
  const createShopAddress = () => {
    if (!shopData) return '';
    const returnData = shopData.prefecture + shopData.city + shopData.address;
    if (!shopData.addressSub) return returnData;
    return returnData + shopData.addressSub;
  };
  const createDeviceSerialList = () => {
    if (!detailData?.devices.length) return '-';
    let deviceList = '';
    detailData?.devices.forEach((data) => {
      deviceList = deviceList + data.deviceSerialCode + ',';
    });
    const regexp = /,$/;
    deviceList = deviceList.replace(regexp, '');
    return deviceList;
  };
  const updatedeliveryNote = async () => {
    await store.dispatch('ship/patchDeliveryNote', {
      id: props.shipId,
      note: deliveryNoteForm.value.note
    });
    initialize();
    deliveryNoteDialog.value = false;
  };
  const handlePrint = () => {
    window.print();
  };
  const addTableData = (text: string): string => {
    return text ? text : '-';
  };
  initialize();
</script>

<style lang="scss">
  .ship-note {
    hr {
      width: 670px;
    }
    @media print {
      .sheet {
        zoom: 150%;
      }
      #content {
        float: none;
        width: 100%;
      }
      .button-area {
        display: none;
      }
    }

    .button-area {
      margin-top: 20px;
    }
    .el-button {
      span {
        color: #ffffff;
        font-weight: 600;
      }
    }

    * {
      color: #191970;
      font-family: '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体',
        YuGothic, sans-serif;
      font-size: 11pt;
      font-weight: normal;
      -webkit-print-color-adjust: exact;
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
    .sheet {
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      break-after: auto;
      height: 285mm;
      width: 210mm;
      padding: 5mm 15mm;
      box-sizing: border-box;

      background: #ffffff;
      box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
      margin: 5mm auto;
    }

    .text-left {
      text-align: left;
    }
    .text-center {
      text-align: center;
    }
    .text-right {
      text-align: right;
    }
    .header-area {
      height: 12mm;
      text-align: -webkit-right;
      ul {
        width: 200px;
      }
      td {
        font-size: 13px;
      }
      .label {
        width: 80px;
      }
    }
    .address-area {
      margin: 10px;
      height: 45mm;
      text-align: left;

      .company-info {
        width: 67%;
        height: 100%;
        float: left;
        ul {
          li {
            font-size: 15px;
          }
        }
        .company-name {
          font-weight: 600;
          font-size: 20px;
        }
        .address-sub {
          margin-top: -5px;
        }
      }
      .in-house {
        float: left;
        .logo {
          margin-left: 0px;
          img {
            width: 200px;
          }
        }
        ul {
          li {
            font-size: 12px;
            margin-left: 11px;
          }
        }
      }
    }
    .product-detail-area {
      margin-top: 15px;
    }
    .ship-detail-area {
      margin-top: 30px;
    }

    .note-title {
      background: #3366cc;
      font-size: 30px;
      color: #ffffff;
    }

    table th {
      font-weight: normal;
      color: #ffffff;
    }
    table td {
      text-align: right;
    }
    .product-detail-table {
      width: 100%;
      table,
      th,
      td {
        border: 1px black solid;
        border-collapse: collapse;
        padding: 1px 5px;
      }
      tr {
        height: 6mm;
      }
      th {
        background: #3366cc;
      }
      .item {
        width: 80%;
        font-weight: 600;
      }
      .amount {
        width: 10%;
        font-weight: 600;
      }
      .unit {
        width: 10%;
        font-weight: 600;
      }
      .item-cs {
        width: 50%;
        font-weight: 600;
      }
      .amount-cs {
        width: 10%;
        font-weight: 600;
      }
      .jan-code {
        width: 20%;
        font-weight: 600;
      }
      .jan-item-number {
        width: 20%;
        font-weight: 600;
      }
      .space {
        border-left-style: hidden;
        border-bottom-style: hidden;
      }
      .dataline:nth-child(odd) td {
        background-color: #ccddff;
      }
      .dataline:nth-child(even) td {
        background-color: #ffffff;
      }
    }

    .ship-detail-table {
      width: 100%;
      table-layout: fixed;
      table,
      th,
      td {
        word-break: break-all;
        white-space: pre-wrap;
        border: 1px black solid;
        border-collapse: collapse;
        padding: 1px 5px;
      }
      tr {
        height: 6mm;
      }
      .item {
        width: 25%;
        font-weight: 600;
      }
      .target {
        font-weight: 600;
      }
      .space {
        border-left-style: hidden;
        border-bottom-style: hidden;
      }
      th {
        background: gray;
      }
      .dataline:nth-child(odd) td {
        background-color: #f0f0f0;
      }
      .dataline:nth-child(even) td {
        background-color: #ffffff;
      }
    }
    .ship-detail-complete-dialog {
      .el-dialog__title {
        font-weight: 600;
      }
      form {
        width: 560px;
      }
    }
  }
</style>
