/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NumberData } from '@/types/common';
import { AcceptSubmitData } from '@/types/accept';
import { formatDate } from '@/libs/dateFormat';
import { deviceType } from '@/libs/device';

export const acceptType: NumberData = {
  1: '通常',
  2: '返品',
  3: 'バルク発注'
};

export const depotName: NumberData = {
  1: '岐阜'
};

export const shipSelectType: NumberData = {
  1: '販売店',
  2: 'サポート店',
  3: 'その他'
};

export const ignitionType: NumberData = {
  1: 'キー',
  2: 'プッシュ',
  3: 'その他',
  4: 'キー02'
};

export const businessTypeToLabel: NumberData = {
  1: '金融',
  2: 'カーセキュリティ'
};

export const contractTypeToLabel: NumberData = {
  1: '1年一括',
  2: '2年一括',
  3: '3年一括',
  9: '月額'
};

export const defaultSubmitData: AcceptSubmitData = {
  acceptDate: Date.now(),
  sfOrderDate: null,
  sfOrderCode: null,
  shopId: null,
  companyId: null,
  companyGroupId: null,
  financeId: null,
  financeCode: '',
  customerCode: '',
  type: 1,
  harnessId: null,
  isNewCar: true,
  firstRegistYear: '',
  vin: '',
  carMakerId: null,
  carType: '',
  carModel: '',
  ignitionType: 1,
  arrivalDate: null,
  shipZip: '',
  shipPrefecture: '',
  shipCity: '',
  shipAddress: '',
  shipAddressSub: '',
  shipTel: '',
  shipFax: '',
  shipEmail: '',
  shipStaffName: '',
  shipTargetName: '',
  note: '',
  depotId: 1,
  details: [],
  shipName: '',
  shipSelectType: null,
  isHarnessCut: false,
  supportShopId: null,
  orderCompanyId: null,
  shipNote: '',
  isShip: true,
  deviceType: 3,
  businessType: 1,
  orderNumber: '',
  contractType: null
};

export function acceptStatus(status: number, remand = false): string {
  if (status === 1) return '受注';
  if (status === 2 && !remand) return '受注確定';
  if (status === 2 && remand) return '差し戻し';
  if (status === 3) return '出荷依頼';
  return '出荷依頼確定';
}

export function createBasicTableData(data: AcceptSubmitData) {
  return [
    {
      label: '事業区分',
      value: businessTypeToLabel[data.businessType]
    },
    {
      label: '発注番号',
      value: data.orderNumber ? data.orderNumber : '-'
    },
    {
      label: '契約区分',
      value: data.contractType ? contractTypeToLabel[data.contractType] : '-'
    },
    {
      label: '受注日',
      value: data.acceptDate ? formatDate(Number(data.acceptDate)) : '-'
    },
    {
      label: 'SF発注番号',
      value: data.sfOrderCode ? data.sfOrderCode : '-'
    },
    {
      label: 'SF発注日',
      value: data.sfOrderDate ? formatDate(Number(data.sfOrderDate)) : '-'
    },
    {
      label: 'ファイナンス',
      value: ''
    },
    {
      label: 'ファイナンス\r\n管理番号',
      value: data.financeCode ? data.financeCode : '-'
    },
    {
      label: '販売店企業\r\nグループ名',
      value: ''
    },
    {
      label: '販売店企業名',
      value: ''
    },
    {
      label: '販売店名',
      value: ''
    },
    {
      label: '販売店担当者',
      value: data.shipStaffName
    },
    {
      label: '顧客管理番号',
      value: data.customerCode ? data.customerCode : '自動'
    },
    {
      label: '伝票区分',
      value: acceptType[data.type]
    },
    {
      label: '出荷作業',
      value: data.isShip ? '有' : '無'
    },
    {
      label: '確定端末',
      value: ''
    },
    {
      label: 'MCCSver',
      value: deviceType[data.deviceType]
    }
  ];
}

export function createCarTableData(submit: AcceptSubmitData) {
  return [
    {
      label: 'ハーネスカット',
      value: submit.isHarnessCut ? '利用する' : '利用しない'
    },
    {
      label: '新車/中古車',
      value: submit.isNewCar ? '新車' : '中古車'
    },
    {
      label: 'メーカー',
      value: ''
    },
    {
      label: '車種',
      value: submit.carType
    },
    {
      label: '型式',
      value: submit.carModel
    },
    {
      label: '年式',
      value: submit.firstRegistYear
    },
    {
      label: '車台番号',
      value: submit.vin
    },
    {
      label: 'E/Gスタート\r\n方式',
      value: ignitionType[submit.ignitionType]
    }
  ];
}

export function createShipmentTableData(submit: AcceptSubmitData) {
  return [
    {
      label: '取付店種別',
      value: submit.supportShopId ? 'サポート店' : '販売店'
    },
    {
      label: '取付店名',
      value: ''
    },
    {
      label: '発送先種別',
      value: shipSelectType[submit.shipSelectType!]
    },
    {
      label: '発送先店名',
      value: submit.shipName
    },
    {
      label: '郵便番号',
      value: submit.shipZip
    },
    {
      label: '都道府県',
      value: submit.shipPrefecture
    },
    {
      label: '市区町村',
      value: submit.shipCity
    },
    {
      label: '住所',
      value: submit.shipAddress
    },
    {
      label: '住所サブ',
      value: submit.shipAddressSub
    },
    {
      label: '電話番号',
      value: submit.shipTel
    },
    {
      label: 'FAX番号',
      value: submit.shipFax ? submit.shipFax : '-'
    },
    {
      label: 'メールアドレス',
      value: submit.shipEmail ? submit.shipEmail : '-'
    },
    {
      label: '発送先担当者',
      value: submit.shipTargetName
    }
  ];
}
