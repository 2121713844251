/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NumberData } from '@/types/common';
import { ShipDetailData } from '@/types/ship';
import { formatDate, formatDateTime } from '@/libs/dateFormat';
import { deviceType } from '@/libs/device';

export const shipStatus: NumberData = {
  1: '依頼',
  2: '準備中',
  3: '完了'
};

export const shipType: NumberData = {
  1: '出荷',
  2: '返品',
  3: 'バルク'
};

export const simStatus: NumberData = {
  0: '休止',
  1: '開通',
  2: '取得不可'
};

// const ignitionType: NumberData = {
//   1: 'キー',
//   2: 'プッシュ',
//   3: 'その他',
//   4: 'キー02'
// };

export const businessTypeToLabel: NumberData = {
  1: '金融',
  2: 'カーセキュリティ'
};

export function createAcceptTableData(data: ShipDetailData) {
  return [
    {
      label: '出荷番号',
      value: data.id
    },
    {
      label: '受注日',
      value: formatDate(Number(data.acceptDate))
    },
    {
      label: '事業区分',
      value: businessTypeToLabel[data.businessType]
    },
    {
      label: '発注番号',
      value: data.orderNumber ? data.orderNumber : '-'
    },
    {
      label: '顧客管理番号',
      value: data.customerCode ? data.customerCode : '-'
    },
    {
      label: '発送種別',
      value: shipType[data.type]
    },
    {
      label: 'ステータス',
      value: shipStatus[data.status]
    },
    {
      label: 'ファイナンス',
      value: data.financeName
    },
    {
      label: '確定端末',
      value: data.orderCompanyName ? data.orderCompanyName : '-'
    },
    {
      label: '出荷拠点',
      value: data.depotName
    },
    {
      label: 'MCCSver',
      value: deviceType[data.deviceType]
    }
  ];
}

export function createCarTableData(data: ShipDetailData) {
  return [
    {
      label: 'ハーネスカット',
      value: data.harnessId ? '-' : '対象'
    },
    {
      label: 'メーカー',
      value: data.carMakerName
    },
    {
      label: '型式',
      value: data.carModel
    },
    {
      label: '車種',
      value: data.carType
    },
    {
      label: '車台番号',
      value: data.vin
    }
    // {
    //   label: 'E/Gスタート',
    //   value: ignitionType[data.ignitionType]
    // }
  ];
}

export function createShipmentTableData(data: ShipDetailData) {
  return [
    {
      label: '店名',
      value: data.shipName
    },
    {
      label: '郵便番号',
      value: data.shipZip
    },
    {
      label: '住所',
      value: data.shipPrefecture + data.shipCity + data.shipAddress
    },
    {
      label: '住所サブ',
      value: data.shipAddressSub ? data.shipAddressSub : ''
    },
    {
      label: '電話番号',
      value: data.shipTel
    },
    {
      label: '担当者',
      value: data.shipTargetName
    },
    {
      label: '着指定日',
      value: data.arrivalDate ? formatDate(Number(data.arrivalDate)) : '-'
    }
  ];
}
export function createRemarkTableData(data: ShipDetailData) {
  return [
    {
      label: '備考',
      value: data.acceptNote
    },
    {
      label: '作成日時',
      value: formatDateTime(Number(data.createdAt))
    },
    {
      label: '作成者',
      value: data.registerUserName
    },
    {
      label: '更新日時',
      value: formatDateTime(Number(data.updatedAt))
    },
    {
      label: '更新者',
      value: data.updateUserName
    }
  ];
}
export function createShipInputTableData(data: ShipDetailData) {
  return [
    {
      label: '出荷日',
      value: data.shipDate ? formatDate(Number(data.shipDate)) : '-'
    },
    {
      label: '着日',
      value: data.receiptDate ? formatDate(Number(data.receiptDate)) : '-'
    }
  ];
}

export function getPrintName(
  harnessName: string,
  data: ShipDetailData
): string {
  const freedMatchCarModel = [
    'DBA-GB5-',
    'DBA-GB6-',
    'DAA-GB7-',
    'DAA-GB8-',
    '6BA-GB5',
    '6BA-GB6',
    '6AA-GB7',
    '6AA-GB8'
  ];
  if (
    harnessName === 'HP24' &&
    freedMatchCarModel.some((model) => model === data.carModel)
  ) {
    return 'ホンダフリードGB5,GB6,GB7,GB8専用';
  }
  if (harnessName === 'TP1' && data.carMakerId === 7) {
    return 'スズキ用';
  }
  if (data.carType === 'CR-V HB') {
    return 'CR-Vハイブリッド（6AA-RT5,6AA-RT6）';
  }
  if (data.carType === 'CR-V e:HEV') {
    return 'CR-Ve:HEV（6AA-RT5,6AA-RT6）';
  }
  const harnessMap: Record<string, Record<string, string>> = {
    'DK22-02': {
      ハイゼット:
        'ハイゼット、アトレー（S7#0V, S7#0W系）,ピクシスバン（S7#M系）,サンバーバン（S7#0B系）',
      アトレー:
        'ハイゼット、アトレー（S7#0V, S7#0W系）,ピクシスバン（S7#M系）,サンバーバン（S7#0B系）',
      ピクシスバン:
        'ハイゼット、アトレー（S7#0V, S7#0W系）,ピクシスバン（S7#M系）,サンバーバン（S7#0B系）',
      サンバーバン:
        'ハイゼット、アトレー（S7#0V, S7#0W系）,ピクシスバン（S7#M系）,サンバーバン（S7#0B系）',
      default: '基本資料'
    },
    DP21: {
      default: '基本資料'
    },
    HP22: {
      default: '基本資料'
    },
    HP23: {
      グレイス: 'ホンダグレイスGM#専用',
      default: '基本資料'
    },
    HP24: { default: '基本資料' },
    HP25: { default: '基本資料' },
    HP26: { default: '基本資料' },
    HP27: { default: '基本資料' },
    NP22: {
      エルグランド: 'エルグランド（E52系）',
      フーガ: 'フーガ,フーガハイブリッド,シーマ,シーマハイブリッド（Y51系）',
      シーマ: 'フーガ,フーガハイブリッド,シーマ,シーマハイブリッド（Y51系）',
      default: '基本資料'
    },
    'NP21-02': {
      default: 'ニッサン エクストレイル T32専用'
    },
    'NP23-02': {
      キックス: 'キックス（P15系）',
      セレナ: 'セレナ,セレナe-Power（C27系）,エクストレイル（T32系）',
      エクストレイル: 'セレナ,セレナe-Power（C27系）,エクストレイル（T32系）',
      デイズ: 'デイズ（B4#W系）,ルークス（B4#A系）,ekシリーズ（B3#W,B3#A系）',
      ルークス: 'デイズ（B4#W系）,ルークス（B4#A系）,ekシリーズ（B3#W,B3#A系）',
      eK: 'デイズ（B4#W系）,ルークス（B4#A系）,ekシリーズ（B3#W,B3#A系）',
      'ノート ePower': 'ノートe-Power（E13系）',
      default: '基本資料'
    },
    'NP25-02': {
      スカイライン:
        'スカイライン、クーペ（V36系）,スカイラインクロスオーバー（J50系）フェアレディZ（Z34系）',
      クーペ:
        'スカイライン、クーペ（V36系）,スカイラインクロスオーバー（J50系）フェアレディZ（Z34系）',
      スカイラインクロスオーバー:
        'スカイライン、クーペ（V36系）,スカイラインクロスオーバー（J50系）フェアレディZ（Z34系）',
      フェアレディZ:
        'スカイライン、クーペ（V36系）,スカイラインクロスオーバー（J50系）フェアレディZ（Z34系）',
      ティアナ: 'ティアナ（J32系）,ムラーノ（Z51系）',
      ムラーノ: 'ティアナ（J32系）,ムラーノ（Z51系）',
      default: '基本資料'
    },
    'SP22-02': { default: '基本資料' },
    'SP25-02': {
      ワゴンR:
        'スズキ ワゴンR DBA-MH34（1型～2型） スズキ スペーシア DBA-MK32S、DBA-MK42S マツダ フレア DBA-MJ34S（1型～2型）マツダ フレアワゴン DBA-MM32S、DBA-MM42S',
      スペーシア:
        'スズキ ワゴンR DBA-MH34（1型～2型） スズキ スペーシア DBA-MK32S、DBA-MK42S マツダ フレア DBA-MJ34S（1型～2型）マツダ フレアワゴン DBA-MM32S、DBA-MM42S',
      スペーシアハイブリッド:
        'スズキ ワゴンR DBA-MH34（1型～2型） スズキ スペーシア DBA-MK32S、DBA-MK42S マツダ フレア DBA-MJ34S（1型～2型）マツダ フレアワゴン DBA-MM32S、DBA-MM42S',
      フレアワゴン:
        'スズキ ワゴンR DBA-MH34（1型～2型） スズキ スペーシア DBA-MK32S、DBA-MK42S マツダ フレア DBA-MJ34S（1型～2型）マツダ フレアワゴン DBA-MM32S、DBA-MM42S',
      フレアワゴンハイブリッド:
        'スズキ ワゴンR DBA-MH34（1型～2型） スズキ スペーシア DBA-MK32S、DBA-MK42S マツダ フレア DBA-MJ34S（1型～2型）マツダ フレアワゴン DBA-MM32S、DBA-MM42S'
    },
    'TP21-02': { default: '基本資料' },
    'TP22-02': { default: '基本資料' },
    NP21: { default: '(資料なし)' },
    TP24: { default: '(資料なし)' },
    ZP22: { default: '(資料なし)' },
    SP25: { default: '(資料なし)' },
    BP21: { default: '(資料なし)' },
    BP24: { default: '(資料なし)' },
    NP27: { default: '(資料なし)' },
    NP28: { default: '(資料なし)' },
    SP26: { default: '(資料なし)' },
    HP29: { default: '(資料なし)' },
    TP24B: { default: '(資料なし)' },
    // ####ここからMCCS2####
    TP1: {
      ハイエース: 'ハイエース用',
      default: '基本資料'
    },
    NP2: {
      エルグランド: 'エルグランド（E52系）',
      フーガ: 'フーガ,フーガハイブリッド,シーマ,シーマハイブリッド（Y51系）',
      フーガハイブリッド:
        'フーガ,フーガハイブリッド,シーマ,シーマハイブリッド（Y51系）',
      シーマ: 'フーガ,フーガハイブリッド,シーマ,シーマハイブリッド（Y51系）',
      シーマハイブリッド:
        'フーガ,フーガハイブリッド,シーマ,シーマハイブリッド（Y51系）',
      default: 'NP2.pdf'
    },
    'NP3-02': {
      キックス: 'キックス e-Power（P15系）',
      セレナ: 'セレナ e-Power（C27系）',
      デイズ: 'デイズ（B4#W系）/ ルークス（B4#A系）/ eKシリーズ（B3#W,B3#A）',
      ルークス: 'デイズ（B4#W系）/ ルークス（B4#A系）/ eKシリーズ（B3#W,B3#A）', //eKなんて車両ないんだけど
      'ノート ePower': 'ノートe-Power（E13系）',
      default: '基本資料'
    },
    'NP4-02': {
      セレナ:
        'セレナ（C27系）※e-Power以外 / エクストレイル（Ｔ32系）※Ｈ30排ガス記号車',
      'セレナ ハイブリッド':
        'セレナ（C27系）※e-Power以外 / エクストレイル（Ｔ32系）※Ｈ30排ガス記号車',
      エクストレイル:
        'セレナ（C27系）※e-Power以外 / エクストレイル（Ｔ32系）※Ｈ30排ガス記号車'
    },
    'NP5-02': {
      スカイライン:
        'スカイライン（DBA-V36,DBA-PV36,DBA-KV36,DBA-NV36） スカイラインクーペ（DBA-CKV36） スカイラインクロスオーバー（DBA-J50,DBA-NJ50）',
      スカイラインクーペ:
        'スカイライン（DBA-V36,DBA-PV36,DBA-KV36,DBA-NV36） スカイラインクーペ（DBA-CKV36） スカイラインクロスオーバー（DBA-J50,DBA-NJ50）',
      スカイラインクロスオーバー:
        'スカイライン（DBA-V36,DBA-PV36,DBA-KV36,DBA-NV36） スカイラインクーペ（DBA-CKV36） スカイラインクロスオーバー（DBA-J50,DBA-NJ50）',
      フェアレディZ: 'フェアレディZ（CBA-Z34,CBA-HZ34,4BA-Z34）',
      ティアナ: 'ティアナ（DBA-J32,CBA-PJ32,CBA-TNJ32）',
      ムラーノ: 'ムラーノ（CBA-TNZ51,CBA-PNZ51,CBA-TZ51）',
      default: '基本資料'
    },
    HP4: {
      'フリード / フリードスパイク': 'フリード（GB5～8系）',
      'フリードハイブリッド / フリードプラスハイブリッド':
        'フリード（GB5～8系）',
      default: '基本資料'
    },
    HP3: {
      グレイス: 'グレイス（GB系）',
      グレイスハイブリッド: 'グレイス（GB系）'
    },
    BP4: {
      'デリカＤ：５': 'デリカD:5（3DA-CV1W）'
    },
    HP7: {
      'CR-V': 'CR-V（DBA-RW1,DBA-RW2,6BA-RW1,6BA-RW2）',
      シビック: 'シビック（FC1/FK7/FK8）'
    },
    'DK2-02': {
      ハイゼット: 'ダイハツ ハイゼット/アトレー（S7#0V/S7#0W）',
      アトレー: 'ダイハツ ハイゼット/アトレー（S7#0V/S7#0W）',
      ピクシスバン: 'トヨタ ピクシスバン（S7#0M）スバル サンバーバン（S7#0B）', //ここ説明する
      サンバーバン: 'トヨタ ピクシスバン（S7#0M）スバル サンバーバン（S7#0B）',
      default: '基本資料'
    },
    HP2: {
      'N-WGN / カスタム': 'N-WGN（JH3/JH4）用',
      default: '基本資料'
    },
    HP2A: { default: '(資料なし)' },
    HP2B: { default: '(資料なし)' },
    HP2C: { default: '(資料なし)' }
  };
  const value = harnessMap[harnessName];
  if (value !== undefined) {
    const matchingKey = Object.keys(value).find((key) =>
      data.carType.includes(key)
    );
    return matchingKey ? value[matchingKey] : value['default'];
  }
  if (data.deviceType == 2) {
    return '基本資料';
  }
  if (data.ignitionType == 1 || data.ignitionType == 4) {
    return '(資料なし)';
  }
  return '基本資料';
}

export function getQrURL(harnessName: string): string | boolean {
  switch (harnessName) {
    case 'HP27':
      return 'https://sites.google.com/global-mobility-service.com/jy4gunw2qwmkguzzifkdu7ek/ホーム';
    case 'HP25':
      return 'https://sites.google.com/global-mobility-service.com/9yakjyz5m5d69tjpvc9k8s6b/ホーム';
    case 'HP24':
      return 'https://sites.google.com/global-mobility-service.com/bf7fhym3xdiz8yrue2ywnsfk/ホーム';
    case 'HP23':
      return 'https://sites.google.com/global-mobility-service.com/fynd6i854piu3d6tamp3335v/ホーム';
    case 'HP22':
      return 'https://sites.google.com/global-mobility-service.com/5igi5b2ihvragvcvuqy9msv2/ホーム';
    case 'NP25-02':
      return 'https://sites.google.com/global-mobility-service.com/9t7t8aek3kb9vci9kyyyhdqb/ホーム';
    case 'NP23-02':
      return 'https://sites.google.com/global-mobility-service.com/wrfkbm3qd9na945nyp75v6qu/ホーム';
    case 'NP22':
      return 'https://sites.google.com/global-mobility-service.com/2sdf3dp4593a5shsqpetmpbj/ホーム';
    case 'DK22-02':
      return 'https://sites.google.com/global-mobility-service.com/35x94ir2n8eajtdxwn4a5e5x/ホーム';
    case 'SP2225':
      return 'https://sites.google.com/global-mobility-service.com/ytyuyuyw7fusszhuu58jy3nh/ホーム';
    case 'SP25-02':
      return 'https://sites.google.com/global-mobility-service.com/4kiuc9axzkjbr5xxpm997puw/ホーム';
    case 'SP22-02':
      return 'https://sites.google.com/global-mobility-service.com/3n6x4ac6txtp2sh5csmvfbsd/ホーム';
    case 'TP22-02':
      return 'https://sites.google.com/global-mobility-service.com/mgy8inkxhsbhvn9wqks6smqj/ホーム';
    case 'TP21-02':
      return 'https://sites.google.com/global-mobility-service.com/fzvzf2bpqhqbcdpsvpqpet2y/ホーム';
    case 'NP21-02':
      return 'https://sites.google.com/global-mobility-service.com/euxei9dtkrvteuttikfn3cn9/ホーム';
    case 'NP2A-02':
      return 'https://sites.google.com/global-mobility-service.com/zuewmmtnh8kmf6uubtdubhjy/ホーム';
    case 'NP2B-02':
      return 'https://sites.google.com/global-mobility-service.com/ucmx4wtuvjnkpyiq8ii9edy3/ホーム';
    case 'NP2C-02':
      return 'https://sites.google.com/global-mobility-service.com/2htc765u9avtd53z33iudn5p/ホーム';
    default:
      return false;
  }
}
