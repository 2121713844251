<template>
  <BaseSimpleLayout class="purchase-list">
    <template #title>仕入一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button size="large" type="success" @click="moveToCreate">
        新規登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-form inline="true" :model="filterForm">
        <div class="date-filter-area">
          <span class="label">拠点</span>
          <el-select
            v-model="depotFilterForm.depotName"
            placeholder="検索"
            @change="filterPurchase"
          >
            <el-option
              v-for="data in selectDepots"
              :key="data.id"
              :label="data.name"
              :value="data.name"
              width="50px"
            />
          </el-select>
          <el-form-item label="受注日">
            <span>開始:</span>
            <el-date-picker
              v-model="filterForm.purchaseDateTo"
              type="date"
              placeholder="日付を選択"
              style="width: 140px; margin: 0px 10px 0px 5px"
              format="YYYY-MM-DD"
              value-format="x"
              @change="filterPurchase"
            />
            <span>終了:</span>
            <el-date-picker
              v-model="filterForm.purchaseDateFrom"
              type="date"
              placeholder="日付を選択"
              style="width: 140px; margin: 0px 10px 0px 5px"
              format="YYYY-MM-DD"
              value-format="x"
              @change="filterPurchase"
            />
          </el-form-item>
          <div class="text-filter-area">
            <el-form-item label="フィルター">
              <el-input
                v-model="filterForm.filterText"
                style="width: 140px"
                clearable
                placeholder="フィルター"
                @input="filterPurchase"
              />
            </el-form-item>
          </div>
          <div class="confirm-filter-area">
            <el-form-item label="完納">
              <el-select
                v-model="filterForm.confirmFilter"
                placeholder="選択なし"
                @change="filterPurchase"
              >
                <el-option label="選択なし" value="" />
                <el-option label="済" value="済" />
                <el-option label="未" value="未" />
              </el-select>
            </el-form-item>
          </div>
          <div class="id-filter-area">
            <el-form-item label="件数">
              <el-input-number
                class="form-count"
                v-model="filterForm.count"
                :min="1"
                :max="100000"
                :controls="false"
                :precision="0"
                @change="filterPurchase"
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        :default-sort="{ prop: 'id', order: 'descending' }"
        empty-text="データが存在しません。"
        @row-click="rowClick"
      >
        <el-table-column property="id" label="ID" align="center" width="55" />
        <el-table-column
          property="depotName"
          label="拠点"
          align="center"
          width="55"
        />
        <el-table-column
          property="orderId"
          label="発注ID"
          align="center"
          width="100"
        />
        <el-table-column
          property="purchaseDate"
          label="仕入日"
          align="center"
          width="95"
        >
          <template #default="scope">
            {{ convertDate(scope.row.purchaseDate) }}
          </template>
        </el-table-column>
        <el-table-column
          property="supplierName"
          label="仕入先名"
          align="center"
          width="150"
        />
        <el-table-column
          property="partCode"
          label="品番"
          align="center"
          width="100"
        />
        <el-table-column
          property="itemName"
          label="品名"
          align="center"
          width="300"
        />
        <el-table-column
          property="lotQty"
          label="入数"
          header-align="center"
          align="right"
          width="60"
        />
        <el-table-column
          property="purchaseQty"
          label="仕入数"
          align="right"
          header-align="center"
          width="70"
        >
          <template #default="scope">
            {{ scope.row.purchaseQty.toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column
          property="cost"
          label="単価"
          header-align="center"
          align="right"
          width="80"
        >
          <template #default="scope">
            {{
              scope.row.cost.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
          </template>
        </el-table-column>
        <el-table-column
          property="sumCost"
          label="金額"
          header-align="center"
          align="right"
          width="100"
        >
          <template #default="scope">
            {{ (scope.row.cost * scope.row.purchaseQty).toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column property="note" label="備考" align="center" />
        <el-table-column
          property="confirm"
          label="確定"
          align="center"
          width="55"
        >
          <template #default="scope">
            {{ scope.row.isConfirm ? '済' : '' }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref, onBeforeUnmount } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { PurchaseDataState, PurchaseFilterData } from '@/types/purchase';
  import { InventoryFilterForm } from '@/types/inventory';
  import { DepotsDataState } from '@/types/master/depot';
  import { formatDate } from '@/libs/dateFormat';
  import { ElLoading } from 'element-plus';

  onBeforeUnmount(() => {
    clearInterval(reload);
  });

  const store = useStore();
  const tableData = ref<PurchaseDataState[]>([]);
  const depotFilterForm = ref<InventoryFilterForm>({
    depotName: '岐阜'
  });
  const filterForm = ref<PurchaseFilterData>({
    purchaseDateFrom: null,
    purchaseDateTo: null,
    filterText: '',
    status: 0,
    count: 100,
    confirmFilter: null
  });
  const loadingFlag = ref(false);
  const tableHeight = ref(550);
  const selectDepots = ref<DepotsDataState[]>([
    {
      id: 1,
      name: '岐阜',
      zip: '123',
      address: '岐阜県岐阜市',
      tel: '123',
      fax: '123'
    }
  ]);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('purchase/fetch'),
      store.dispatch('depotsMaster/fetch'),
      store.dispatch('itemsMaster/fetch')
    ]);
    selectDepots.value = store.state.depots.listData;
    filterPurchase();
    tableHeight.value = window.innerHeight - 200;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const convertDate = (date: string | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };

  const depotIdToName = (depotId: number) => {
    const depotsMaster = store.state.depotsMaster.replaceData;
    return depotsMaster[depotId] || '-';
  };

  const itemIdToName = (itemId: number) => {
    const itemsMaster = store.state.itemsMaster.replaceData;
    return itemsMaster[itemId].name || '-';
  };

  const filterPurchase = () => {
    const filteredData = store.state.purchase.listData
      .map((data: PurchaseDataState) => {
        return {
          ...data,
          depotName: depotIdToName(data.depotId),
          itemName: itemIdToName(data.itemId)
        };
      })
      .filter((data: PurchaseDataState) => {
        const depotFilterResult =
          !depotFilterForm.value.depotName ||
          data.depotName === depotFilterForm.value.depotName;
        const textFilterLower = filterForm.value.filterText
          ? filterForm.value.filterText.toLowerCase()
          : '';
        const textFilterResult =
          !textFilterLower ||
          data.id.toString().includes(textFilterLower) ||
          data.depotName.toLowerCase().includes(textFilterLower) ||
          data.orderId?.toString().includes(textFilterLower) ||
          data.purchaseDate.includes(textFilterLower) ||
          data.supplierName.toLowerCase().includes(textFilterLower) ||
          data.partCode.toLowerCase().includes(textFilterLower) ||
          data.itemName.toLowerCase().includes(textFilterLower) ||
          data.lotQty.toString().includes(textFilterLower) ||
          data.purchaseQty.toString().includes(textFilterLower) ||
          data.cost.toString().includes(textFilterLower) ||
          (data.cost * data.purchaseQty).toString().includes(textFilterLower) ||
          data.note.toLowerCase().includes(textFilterLower) ||
          (data.isConfirm ? '済' : '').includes(textFilterLower);

        const dateFilterResult = filterDate(data);
        let confirmFilterResult = true;
        if (filterForm.value.confirmFilter === '済') {
          confirmFilterResult = data.isConfirm;
        } else if (filterForm.value.confirmFilter === '未') {
          confirmFilterResult = !data.isConfirm;
        }
        return (
          depotFilterResult &&
          textFilterResult &&
          dateFilterResult &&
          confirmFilterResult
        );
      });
    tableData.value = filteredData.slice(0, filterForm.value.count);
  };

  const filterDate = (data: PurchaseDataState): boolean => {
    let purchaseToResult = !filterForm.value.purchaseDateTo;
    let purchaseFromResult = !filterForm.value.purchaseDateFrom;
    if (filterForm.value.purchaseDateTo && data.purchaseDate) {
      purchaseToResult = filterForm.value.purchaseDateTo <= data.purchaseDate;
    }
    if (filterForm.value.purchaseDateFrom && data.purchaseDate) {
      purchaseFromResult =
        data.purchaseDate < filterForm.value.purchaseDateFrom;
    }
    return purchaseToResult && purchaseFromResult;
  };

  const rowClick = (data: PurchaseDataState) => {
    router.push('/purchase/' + data.id);
  };

  const moveToCreate = () => {
    router.push('/purchase/create');
  };
  initialize();
  const reload = setInterval(initialize, 1000 * 60 * 3);
</script>
<style lang="scss">
  .purchase-list {
    text-align: center;
    .el-form-item {
      margin: 5px 0;
      .el-form-item__label {
        font-weight: 600;
      }
    }
    .main-container {
      width: 98%;
    }

    tbody {
      font-size: 13px;
      font-weight: 500;
    }

    thead {
      font-size: 14px;
    }

    .el-form {
      display: inline-flex;
    }

    .el-form-item {
      text-align: left;
      margin-bottom: 10px;

      .el-form-item__label {
        font-weight: 600;
      }
    }

    .form-group {
      margin-bottom: 0px;
    }

    .date-filter-area {
      min-width: 1280px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: auto;
      padding-right: auto;

      .label,
      .el-form-item,
      .el-select,
      .el-date-picker {
        margin-right: 15px;
      }

      .label {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .confirm-filter-area {
      .el-input {
        width: 100px;
      }
    }

    .id-filter-area {
      label {
        width: 60px !important;
      }
    }

    .form-count {
      width: 90px;

      .el-input {
        width: 90px;
      }
    }

    .el-checkbox {
      font-weight: 600;
    }

    .el-table {
      margin: 10px auto 0;
      width: 1400px;
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }

    @media print {
      .main-container {
        width: 100%;
      }

      .base-header {
        display: none;
      }

      .el-form {
        display: none;
      }

      .main {
        padding-top: 0;
      }
    }

    // .disp-count {
    //     text-align: left;
    // }
  }
</style>
