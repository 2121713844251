<!-- TODO: 色々とリファクタリングが必要 -->
<template>
  <div v-if="loadingFlag" class="ship-note3">
    <div class="button-area">
      <el-button
        size="large"
        type="info"
        @click="router.push(`/carSecurity/device/detail/${autobacs_id}`)"
      >
        戻る
      </el-button>
      <el-button size="large" type="primary" @click="handlePrint">
        印刷
      </el-button>
    </div>
    <section class="sheet page">
      <div class="note-title2 text-center">
        【取付前に行うこと】SMART BLOCKER アプリ 新規登録のお願い
      </div>
      <div class="intro">
        この度は、SMART
        BLOCKERをお買い求めいただきまして誠にありがとうございます。
      </div>
      <div class="address-area2">
        <ul>
          <span class="notice3">【必ずお読みください】</span>
          <li>
            ・ご利用者様は、最初に下記の流れでご自身のスマートフォンに「SMART
            BLOCKER アプリ（会員制HP）」のご登録を行ってください。
          </li>
          <li>
            ・ご登録にあたり「クレジットカード情報」とセキュリティ通知を受け取る「メールアドレス」が必要です。
          </li>
          <li style="color: red">
            ・ご登録になるメールアドレスは、携帯キャリアメール以外を推奨します。携帯キャリアメールの場合、メールが受け取れないことがあります。
          </li>
          <li>
            ・本用紙は、この端末シリアル番号専用になります。取付後は、ご利用者様の方で大切に保管してください。
          </li>
        </ul>
      </div>
      <div class="remark">
        <span class="notice">
          ①下記QRコードをスマートフォンで読み取り新規登録を行なってください
        </span>
      </div>
      <div class="area">
        <div class="company-info">
          <vue-qrcode
            :value="register_qr"
            tag="svg"
            :options="{
              errorCorrectionLevel: 'Q',
              width: 20
            }"
          ></vue-qrcode>
          <span class="serial">シリアルナンバー <br /></span>
          <span class="serial">{{
            store.state.carSecurityDevice.putData.serialCode
          }}</span>
          <div class="browser-info red">
            ＜対応ブラウザ＞<br />Safari／Google Chrome<br />※最新バージョン
          </div>
        </div>

        <div class="in-house">
          <p class="aste red">
            ※携帯キャリアメールは推奨しません。もし使用される場合は、必ず受信ドメイン指定で「car-security.cloud-gms.com」を登録し、受信可能な状態にしてください。それでもメールが受け取れないことがあります。
          </p>
          <p class="aste">
            ※メールが届かない場合は、迷惑メールフォルダやプロモーションフォルダに振り分けられていないかご確認ください。
          </p>
          <p class="passenger">
            １．ご登録いただいたメールアドレス宛に「本登録のお願いメール」が届きますので、そのメールにあるURLから本登録をお願いいたします。
          </p>
          <p class="aste">
            ※契約プランを選択してクレジットカード情報をご登録いただきます。
          </p>
          <p class="aste">
            ※契約プランの詳細はこちらのQRコードからご確認いただけます。 →→→
          </p>
          <p class="passenger">
            ２．本登録完了メールが届きましたら、②にお進みください。
          </p>
          <p class="aste">
            ※ログインURLは、スマホのメモ機能などに保存しておくことをお勧めします。
          </p>
          <div class="right-qrcode">
            <vue-qrcode
              value="https://sites.google.com/global-mobility-service.com/smart-blocker/ホーム/契約プラン"
              tag="svg"
              :options="{
                errorCorrectionLevel: 'Q',
                width: 80
              }"
            ></vue-qrcode>
          </div>
        </div>
      </div>
      <div class="remark">
        <span class="notice">
          ②SMART BLOCKER アプリ をスマートフォンのホーム画面に追加してください
        </span>
      </div>
      <div class="two">
        <ul>
          <li class="first">
            １．新規登録の手続きを終え、「本登録の完了メール」が届きましたらメール本文にアプリへのログインURLと仮パスワードがございますのでご確認ください。
          </li>
          <li>
            ２．下記の手順でスマートフォンのホーム画面にアプリのアイコンショートカットを追加してログインしてください。<br />
            <strong>・iPhone端末をご利用のお客様</strong><br />
            ①ログインURLをコピーして「Safari」または「Google
            Chrome」に貼り付けて開くか、欄外にあるログイン用QRコードを読み取ってログイン画面を開いてください。<br />
            ②ブラウザ画面下部にあるiPhoneの機能「共有ボタン<img
              src="@/assets/iphone-share.jpg"
              alt="ログイン画面"
              width="10"
              height="10"
            />」を押します。<br />
            ③出てきたメニューをスクロールして「ホーム画面に追加する」を選択し「追加」ボタンを押します。<br />
            <br />

            <strong>・アンドロイド端末をご利用のお客様</strong><br />
            ①メール本文にあるログインURLをクリックして「Google
            Chrome」でログイン画面を開いてください。<br />
            ②ログイン画面下部にある「ホーム画面にSMART BLOCKER
            を追加」を押します。<br />
            ③次に表示される「アプリをインストール」のところで「インストール」を押してください。<br />
          </li>
        </ul>
      </div>
      <div class="remark">
        <span class="notice">
          ③SMART BLOCKER アプリ
          にログインして「プッシュ通知の承諾」をしてください
        </span>
      </div>
      <div class="two">
        <ul>
          <li class="first">
            １．ホーム画面に追加されたスマートブロッカーのアイコンショートカットからログイン画面を表示します。
          </li>
          <li>
            ２．ご登録いただいたメールアドレスと仮パスワードを入力してログインしてください。<br />
            ※仮パスワードでログインできない場合は、画面下部にある「パスワードを忘れたら」をクリックしてパスワード変更をしてください。
          </li>
          <li>
            ３．ログイン時に「プッシュ通知の承諾」を促す画面が表示されますので「承諾」してください。承諾するとメール通知以外にWebプッシュ通知も受け取れるようになります。
          </li>
        </ul>
      </div>
      <div class="four">
        <div class="manual_title">
          <span class="manual">【SMART BLOCKER アプリ QRコード集】<br /></span>
        </div>
        <div class="qr-container">
          <div class="qr-item">
            <div class="qrcode">
              <vue-qrcode
                value="https://app.car-security.cloud-gms.com/login"
                tag="svg"
                :options="{
                  errorCorrectionLevel: 'Q',
                  width: 80
                }"
              ></vue-qrcode>
            </div>
            <div class="qr-title">ログインURL</div>
          </div>
          <div class="qr-item">
            <div class="qrcode">
              <vue-qrcode
                value="https://sites.google.com/global-mobility-service.com/smart-blocker/%E3%83%9B%E3%83%BC%E3%83%A0/add-icon"
                tag="svg"
                :options="{
                  errorCorrectionLevel: 'Q',
                  width: 80
                }"
              ></vue-qrcode>
            </div>
            <div class="qr-title">アイコンショートカット追加方法</div>
          </div>
          <div class="qr-item">
            <div class="qrcode">
              <vue-qrcode
                value="https://sites.google.com/global-mobility-service.com/smart-blocker/%E3%83%9B%E3%83%BC%E3%83%A0"
                tag="svg"
                :options="{
                  errorCorrectionLevel: 'Q',
                  width: 80
                }"
              ></vue-qrcode>
            </div>
            <div class="qr-title">ユーザーマニュアル</div>
          </div>
          <!-- 追加のQRコードをここに配置 -->
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import { ElLoading } from 'element-plus';
  import VueQrcode from '@chenfengyuan/vue-qrcode';

  const userURL = import.meta.env.VITE_APP_USER_ENTRY_URL;
  const store = useStore();
  const loadingFlag = ref(false);
  const autobacs_id = store.state.carSecurityDevice.putData.id;
  const register_qr = ref('');
  const initialize = async () => {
    if (!store.state.carSecurityDevice.putData) {
      router.push('/carSecurity/device/detail/' + autobacs_id);
      return;
    }
    register_qr.value =
      `${userURL}/?serial=` + store.state.carSecurityDevice.putData.serialCode;
    const loadingInstance = ElLoading.service({ fullscreen: true });
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const handlePrint = () => {
    window.print();
  };
  initialize();
</script>

<style lang="scss">
  .intro {
    padding-top: 10px;
  }

  .manual {
    font-size: 14px !important;
  }

  .manual_title {
    padding-left: 20px;
    padding-top: 15px;
  }
  .ship-note3 {
    hr {
      width: 670px;
    }
    @media print {
      body {
        margin: 0;
        height: 100%;
      }
      * {
        box-shadow: none !important;
        text-shadow: none !important;
      }
      .sheet {
        zoom: 150%;
        page-break-after: avoid;
      }
      #content {
        float: none;
        width: 100%;
      }
      .button-area {
        display: none;
      }
      .remark,
      .notice {
        background-color: white;
        color: black;
      }
      .notice::before,
      .notice::after {
        background-color: black;
      }
    }

    .button-area {
      margin-top: 20px;
    }
    .el-button {
      span {
        color: #ffffff;
        font-weight: 600;
      }
    }

    * {
      color: black;
      font-family: '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体',
        YuGothic, sans-serif;
      font-size: 8pt;
      font-weight: normal;
      -webkit-print-color-adjust: exact;
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
    .sheet {
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      break-after: auto;
      height: 280mm;
      width: 210mm;
      padding: 5mm 15mm;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
      margin: 5mm auto;
      line-height: 1rem;
      color: black !important;
    }

    .right-qrcode {
      position: absolute;
      top: 350px;
      right: 80px;
    }
    .text-center {
      text-align: center;
    }
    .red {
      color: red;
    }
    .remark {
      text-align: left;
      background-color: black;
    }
    .notice3 {
      display: inline-flex;
      text-align: left;
      position: relative;
      font-size: 16px;
      background-color: black;
      color: white;
      font-weight: 800;
      margin-bottom: 3px;
      line-height: 1.6rem;
    }

    .notice {
      display: inline-flex;
      text-align: left;
      position: relative;
      width: 100%;
      font-size: 16px;
      background-color: black;
      color: white;
      font-weight: 800;
      padding-left: 1em;
      text-indent: -1em;
    }

    .notice::before {
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: black;
    }

    .notice::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: black;
    }
    .remark2 {
      margin-top: 8px;
      padding-top: 8px;
      text-align: left;
      background-color: rgb(218, 218, 218);
      height: 1.5rem;
      width: 102.4%;
    }
    .notice2 {
      text-align: left;
      position: relative;
      font-size: 16px;
      color: black;
      background-color: rgb(218, 218, 218);
    }

    .two {
      text-align: left;
      li {
        padding-top: 10px;
        padding-left: 1em;
        text-indent: -1em;
      }
      li.first {
        padding-top: 0px !important;
      }
    }

    .three {
      text-align: left;
      padding-left: 1em;
      text-indent: -1em;
    }

    .four {
      height: 140px;
      border: solid 1px;
      text-align: left;
      padding-left: 1em;
      text-indent: -1em;
    }

    .qrcode {
      padding-left: 30px;
      width: 80px;
    }

    .text {
      padding-top: 15px;
      padding-left: 50px;
      width: 500px;
      line-height: 1rem;
    }

    .notice2::before {
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: rgb(218, 218, 218);
    }

    .notice2::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: rgb(218, 218, 218);
    }

    .address-area2 {
      margin: 10px;
      // height: 45mm;
      text-align: left;
      .in-house {
        float: left;
        .logo {
          margin-left: 0px;
          img {
            width: 200px;
          }
        }
        ul {
          li {
            font-size: 12px;
            margin-left: 11px;
          }
        }
      }
    }
    .area2 {
      margin-left: 10px;
      text-align: left;
      display: flex;
    }
    .area {
      margin: 10px;
      height: 230px;
      margin-bottom: 16px;
      text-align: left;
      .serial {
        display: block;
        text-align: center;
      }
      .company-info {
        width: 150px;
        height: 185px;
        margin-right: 20px;
        float: left;
        border: solid 1px;
        ul {
          li {
            font-size: 15px;
            line-height: 1rem;
          }
        }
        .company-name {
          font-weight: 600;
          font-size: 20px;
        }
        .address-sub {
          margin-top: -5px;
        }
      }
      .browser-info {
        text-align: center;
      }
      .in-house {
        width: 430px;
        float: left;
        word-break: break-all;
        .logo {
          margin-left: 0px;
          img {
            width: 200px;
          }
        }
        ul {
          li {
            font-size: 12px;
            margin-left: 11px;
          }
        }
        .passenger {
          padding-left: 1em;
          text-indent: -1em;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        .aste {
          padding-left: 2em;
          text-indent: -1em;
          margin-top: 2px;
          margin-bottom: 2px;
        }
      }
    }

    .note-title2 {
      background: black;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 5px;
      line-height: 1.8rem;
    }
    .qr-container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px; /* QRコード間の間隔 */
      justify-content: space-around; /* 均等に配置 */
    }
    .qr-item {
      flex: 1 1 30%; /* 3列にするための設定 */
      display: flex;
      flex-direction: column; /* 縦に並べる */
      align-items: center; /* 中央に配置 */
      text-align: center;
    }
    .qrcode svg {
      max-width: 100%;
    }
    .qr-title {
      margin-left: 25px;
    }

    table th {
      font-weight: normal;
      color: #ffffff;
    }
    table td {
      text-align: right;
    }
  }
</style>
